import React from "react";
import logoBMImg from './assets/logo_bm.png';
import logoSunscoolImg from './assets/logo_sunscool.png';
import logoSunscoolTextImg from './assets/logo_sunscool_text.png';
import headerImg from './assets/header.png';
import headerSunscoolImg from './assets/header_sunscool.png';
//import puzzlImg from './assets/puzzl.png';
//import vertepImg from './assets/vertep.png';
import footerImg from './assets/footer.png';
import footerSunscoolImg from './assets/footer_sunscool.png';
import diamondImg from './assets/diamond.png';
import appleStoreImg from './assets/apple_store.png';
import googlePlayImg from './assets/google_play.png';
import coloringImg from './assets/coloring.png';
import mockupImg from './assets/mockup.png';
import gameSplashImg from './assets/game_splash.jpg';
import cancelImg from './assets/cancel.png';

class App extends React.Component {
  player = null;

  componentDidMount() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2190555,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

    this.showBanner();
    window.addEventListener('scroll', this.showBanner);
    window.addEventListener('fullscreenchange', (e) => {
      if (e.target.closest('#gameContainer') !== null) {
        e.preventDefault();
        return;
      }
    });
    const script = document.createElement('script');
    script.src = 'https://www.youtube.com/iframe_api';
    script.onload = (e) => {
      window.YT.ready(() => {
        this.player = new window.YT.Player('sunscool-install-video-iframe', {
          videoId: 'AB776YOspZE'
        });
      })
    };
    document.body.appendChild(script);
    document.title = "Christmas 2022";
    this.goToPerformances();
    this.goToVertep();
    this.goToPuzzle();
    this.goToPuzzleOnline();
    this.removeMarginBMFooter();
    this.configuringUrl();
    this.getElInstruction().checked = true;
    this.editUrl("Инструкции", '/');
  }

  render() {
    return (
      <React.Fragment>
        <div className="main">
          <section className="col bm-container is-main active" onClick={this.selectSection} onTransitionEnd={this.handleTransition}>
            <div className="overlay">
              <a href="https://biblemission.ru/" className="logo-img" target='_blank'>
                <img src={logoBMImg}/>
              </a>
              <ul>
                <li>
                  <a className="goToPuzzle" href="#puzzle">Пазл</a>
                </li>
                <li>
                  <a className="goToVertep" href="#vertep">Вертеп</a>
                </li>
                <li>
                  <a className="goToPuzzleOnline" href="#puzzle_online">Собрать Пазл онлайн</a>
                </li>
                <li>
                  <a className="goToPerformances" href="#performances">Спектакли</a>
                </li>
              </ul>
              <img src={headerImg}/>
              <div className="bm-footer">
                <div>
                  Библейская миссия
                  <br/>
                  2022
                </div>
              </div>
            </div>
            <header>
              <a href="https://biblemission.ru/" className="logo-img" target='_blank'>
                <img src={logoBMImg}/>
              </a>
              <div className="title-congratulations">С Рождеством!</div>
              <img src={headerImg}/>
            </header>
            <main>
              <div className="tabs">
                <input type="radio" className="instructions" id="instructions" name="tabs" checked="checked" />
                <label className="label-instructions" for="instructions">инструкции</label>
                <div className="tabs_block instructions" id="xmas-card">
                  <div className="bm-main-col">
                    <iframe className="video" src="https://www.youtube.com/embed/HFuBCsL99Ng" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen />
                    <div className="video-title">Бесконечная Рождественская открытка</div>
                  </div>

                  {/*
                  <div className="bm-main">
                    <div className="bm-main-col" id="puzzle">
                      <img src={puzzlImg}/>
                      <div className="title">Пазл</div>
                      <iframe className="video" src="https://www.youtube.com/embed/M1SJUi3Nl28" frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen />
                        <div className="video-title">Сборка Пазла</div>
                    </div>
                    <div className="bm-main-col" id="vertep">
                      <img src={vertepImg}/>
                      <div className="title">Вертеп</div>
                      <iframe className="video" src="https://www.youtube.com/embed/Opwf4UBiwFA" frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen />
                      <div className="video-title">Сборка Вертепа</div>
                    </div>
                  </div>
                  <div className="bm-main-col bingo">
                    <iframe className="video" src="https://www.youtube.com/embed/_TCqJrtIkDI" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen />
                    <div className="video-title">Бинго</div>
                  </div>
                  */}

                  <div className="bm-video-container" id="puzzle_online">
                  <div className="bm-video-title">Собрать пазл онлайн</div>
                    <iframe className="bm-video" src="https://www.jigsawplanet.com/?rc=play&pid=2e5f95207756&view=iframe" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
                  </div>
                </div>
                <input type="radio" className="performances" id="performances" name="tabs" checked="checked" />
                <label className="label-performances" for="performances">спектакли</label>
                <div className="tabs_block" id="performances">
                  <div className="performances_items_wrapper">
                    <div className="performances_items">
                      <div className="performances_item">
                        <div className="performances_item_video">
                          <iframe className="performances_item_video" src="https://www.youtube.com/embed/ALmV12jVlL8" title="YouTube video player" frameborder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="performances_item_title">
                          <p>"Краски Великого Мастера"</p>
                        </div>
                      </div>
                      <div className="performances_item">
                        <div className="performances_item_video">
                          <iframe className="performances_item_video" src="https://www.youtube.com/embed/wxA6Me8sVBw" title="YouTube video player" frameborder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="performances_item_title">
                          <p>"Звездный мальчик"</p>
                        </div>
                      </div>
                    </div>

                    {/*
                    <div className="performances_items">
                      <div className="performances_item">
                        <div className="performances_item_video">
                          <iframe className="performances_item_video" src="https://www.youtube.com/embed/QqyI1N-xT14" title="YouTube video player" frameborder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="performances_item_title">
                          <p>"Тайна рождественской звезды"</p>
                        </div>
                      </div>
                      <div className="performances_item">
                        <div className="performances_item_video">
                          <iframe className="performances_item_video" src="https://www.youtube.com/embed/igu8IpASw7I" title="YouTube video player" frameborder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="performances_item_title">
                          <p>"Тайна моего сердца"</p>
                        </div>
                      </div>
                    </div>
                    */}

                    <div className="performances_items">
                      <div className="performances_item">
                        <div className="performances_item_video">
                          <iframe className="performances_item_video" src="https://www.youtube.com/embed/NuYu6f3I8qU" title="YouTube video player" frameborder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="performances_item_title">
                          <p>"Взгляд под маску"</p>
                        </div>
                      </div>
                      <div className="performances_item">
                        <div className="performances_item_video">
                          <iframe className="performances_item_video" src="https://www.youtube.com/embed/t-PcW9B6yyg" title="YouTube video player" frameborder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="performances_item_title">
                          <p>"Свет в городе Одиночества"</p>
                        </div>
                      </div>
                    </div>
                    <div className="performances_items">
                      {/*
                      <div className="performances_item">
                        <div className="performances_item_video">
                          <iframe className="performances_item_video" src="https://www.youtube.com/embed/ca_EzSw-OgI" title="YouTube video player" frameborder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="performances_item_title">
                          <p>"Бригантина «Радостная весть»"</p>
                        </div>
                      </div>
                      */}
                      <div className="performances_item">
                        <div className="performances_item_video">
                          <iframe className="performances_item_video" src="https://www.youtube.com/embed/T8LBpfuG-p0" title="YouTube video player" frameborder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="performances_item_title">
                          <p>"Тайна Одной Книги"</p>
                        </div>
                      </div>
                      <div className="performances_item">
                        <div className="performances_item_video">
                          <iframe className="performances_item_video" src="https://www.youtube.com/embed/x2_AHHdk8C8" title="YouTube video player" frameborder="0" 
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="performances_item_title">
                          <p>"Виноградная лоза"</p>
                        </div>
                      </div>
                    </div>

                    {/*
                    <div className="performances_items">
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </main>
            <footer className="bm-footer">
              <img src={footerImg}/>
              <div>
                Библейская миссия
                <br/>
                2022
              </div>
            </footer>
          </section>
          <section className="col sunscool-container" onClick={this.selectSection} onTransitionEnd={this.handleTransition}>
            <div className="overlay">
              <a className="logo-container" href="https://sunscool.org/to/3CCD5A" target='_blank'>
                <img className="logo-img" src={logoSunscoolImg}/>
                <img src={logoSunscoolTextImg}/>
                <div className="logo-text">Воскресная школа</div>
              </a>
              <ul>
                <li>
                  <a href="#game">Игры</a>
                </li>
                <li>
                  <a href="#music">Рождественский клип</a>
                </li>
                <li>
                  <a href="#animation">Мультики</a>
                </li>
                <li>
                  <a href="#coloring">Раскраски</a>
                </li>
                <li>
                  <a href="#bible_lesson">Библейские уроки</a>
                </li>
              </ul>
              <div className="sunscool-footer">
                <img src={footerSunscoolImg}/>
                <div>2022</div>
              </div>
            </div>
            <header>
              <a className="logo-container" href="https://sunscool.org/to/3CCD5A" target='_blank'>
                <img className="logo-img" src={logoSunscoolImg}/>
                <img src={logoSunscoolTextImg}/>
                <div className="logo-text">Воскресная школа</div>
              </a>
              <div className="title">
                <div>200+</div>
                уроков, мультиков и игр
              </div>
              <img src={headerSunscoolImg}/>
            </header>
            <main>
              <div className="sunscool-install-container">
                <div className="sunscool-install-video">
                  <img src={mockupImg} alt="" onClick={this.openSunscoolVideo}/>
                  <div className='sunscool-install-video-iframe'>
                    <img className='sunscool-install-video-cancel' src={cancelImg} alt="" onClick={this.closeSunscoolVideo}/>
                    <div id='sunscool-install-video-iframe' />
                  </div>
                </div>
                <div className="sunscool-install-info">
                  <img className="sunscool-install-img" src={diamondImg}/>
                  <div className="sunscool-install-title">
                    Установи "Во! Школа" введи этот код и получи
                    <div>500 алмазов</div>
                  </div>
                  <div className="sunscool-install-code">
                    <div className="sunscool-install-code-item">a</div>
                    <div className="sunscool-install-code-item">0</div>
                    <div className="sunscool-install-code-item">0</div>
                    <div className="sunscool-install-code-item">5</div>
                    <div className="sunscool-install-code-item">4</div>
                  </div>
                  <div className="sunscool-install-stores">
                    <a href="https://sunscool.org/to/rozhdestvo-apple" target="_blank">
                      <img src={appleStoreImg}/>
                    </a>
                    <a href="https://sunscool.org/to/rozhdestvo-google" target="_blank">
                      <img src={googlePlayImg}/>
                    </a>
                  </div>
                </div>
              </div>
              <div className="sunscool-game-container" id="game">
                <div className="sunscool-game-title">
                  Поиграй сейчас
                  <div>Найди Рождественскую звезду</div>
                </div>
                <div className="sunscool-game" id="gameContainer">
                  <img src={gameSplashImg} alt="" onClick={this.loadGame}/>
                </div>
                <div className="sunscool-install-game">
                  <a href="https://sunscool.org/to/rozhdestvo-xmas-star" target="_blank">
                    <img src={googlePlayImg}/>
                  </a>
                </div>
              </div>
              <div className="sunscool-video-container" id="music">
                <div className="sunscool-video-title">Рождественский клип<br />«Нам родился Иисус»</div>
                <iframe className="sunscool-video" src="https://www.youtube.com/embed/uFetdd5c45I" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
              </div>
              <div className="sunscool-video-container" id="animation">
                <div className="sunscool-video-title">Мультик "Рождество"</div>
                <iframe className="sunscool-video" src="https://www.youtube.com/embed/e_f3bjvKxV8" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
              </div>
              <div className="sunscool-coloring" id="coloring">
                <div className="sunscool-coloring-info">
                  <div className="sunscool-coloring-title">
                    Рождественская Антистресс раскраска
                  </div>
                  <div className="sunscool-coloring-btn-container">
                    <a href="https://sunscool.org/to/rozhdestvo-coloring" className="sunscool-coloring-btn" target='_blank'>Скачать PDF</a>
                  </div>
                </div>
                <img src={coloringImg} className="sunscool-coloring-img"/>

                <div className="sunscool-coloring-btn-container mobile">
                  <a href="https://sunscool.org/to/rozhdestvo-coloring" className="sunscool-coloring-btn">Скачать PDF</a>
                </div>
              </div>
            </main>
            <footer className="sunscool-footer">
              <img src={footerSunscoolImg}/>
              <div>2022</div>
            </footer>
          </section>
        </div>
        <div className="bm-banner" onClick={this.selectBMBanner}>
          <img src={logoBMImg}/>
          <div className="bm-banner-title">
            Собери пазл и вертеп
          </div>
        </div>
        <div className="sunscool-banner" onClick={this.selectSunscoolBanner}>
          <img src={logoSunscoolImg}/>
          <div className="sunscool-banner-title">
            <div>200+</div>
            уроков, мультиков и игр
          </div>
        </div>
      </React.Fragment>
    );
  }

  getElInstruction () {
    const instructions = document.querySelector('.instructions');
    return (instructions)
  }

  getElPerformances () {
    const performances = document.querySelector('.performances');
    return performances
  }

  editUrl(title, url) {
    const obj = { Title: title, Url: url };
    window.history.pushState(obj, obj.Title, obj.Url);
    return obj
  }

  checkingStatusChexbox() {
    if (this.getElPerformances().checked == true) {
      document.querySelector('footer.bm-footer').className += " remove-margin";
    }
    if (this.getElInstruction().checked == true) {
      this.editUrl("Инструкции", '/')
      document.querySelector('footer.bm-footer').classList.remove("remove-margin");
    }
  }

  configuringUrl() {
    if (typeof(window.history.pushState) !== "undefined") {
      this.getElInstruction().addEventListener('click', (e) => {
        this.editUrl("Инструкции", '/')
        this.checkingStatusChexbox()
      })
      this.getElPerformances().addEventListener('click', (e) => {
        if (this.getElPerformances().checked == true) {
          this.editUrl("Спектакли", '/performances')
          this.checkingStatusChexbox()
        }
      })
      document.querySelector('.sunscool-container').addEventListener('click', (e) => {
        this.editUrl("SunScool", '/sunscool')
      })
    } else {
      console.warn("Browser does not support `history.pushState`");
    }
  }

  goToVertep() {
    document.querySelector('.goToVertep').addEventListener('click', (e) => {
      this.getElInstruction().checked = true;
      this.editUrl("Инструкции", '/')
      this.checkingStatusChexbox()
    })
  }

  goToPuzzle() {
    document.querySelector('.goToPuzzle').addEventListener('click', (e) => {
      this.getElInstruction().checked = true;
      this.editUrl("Инструкции", '/')
      this.checkingStatusChexbox()
    })
  }

  goToPuzzleOnline() {
    document.querySelector('.goToPuzzleOnline').addEventListener('click', (e) => {
      this.getElInstruction().checked = true;
      this.editUrl("Инструкции", '/')
      this.checkingStatusChexbox()
    })
  }

  goToPerformances() {
    document.querySelector('.goToPerformances').addEventListener('click', (e) => {
      this.getElPerformances().checked = true;
      this.editUrl("Спектакли", '/performances')
      this.checkingStatusChexbox()
    })
  }

  removeMarginBMFooter() {
    this.getElPerformances().addEventListener('change', (e) => {
      this.checkingStatusChexbox()
    })

    this.getElInstruction().addEventListener('change', (e) => {
      this.checkingStatusChexbox()
    })
  }

  selectSection = (e) => {
    const target = e.target || e;
    const col = target.closest('.main > .col');
    if (col === null ||  col.classList.contains('is-main')) {
      return;
    }
    const cols = document.querySelectorAll('.main > .col');
    for (let j = 0; j < cols.length; j++) {
      cols[j].classList.remove('active');
      cols[j].classList.remove('is-main');
    }
    col.classList.add('is-main');
    if (window.innerWidth <= 600) {
      return;
    }
    if (target.closest('a') !== null && target.closest('ul') !== null) {
      e.preventDefault();
      col.dataset['target'] = target.getAttribute('href');
    } else  {
      col.dataset['target'] = '';
    }
  }

  handleTransition = (e) => {
    const target = e.target.closest('.main > .col');
    const isMain = target.classList.contains('is-main');
    target.classList.toggle('active', isMain);
    setTimeout(function () {
      if (isMain && target.dataset['target']) {
        const element = document.querySelector(target.dataset['target']);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }, 300);
  }

  selectSunscoolBanner = () => {
    const sunscoolContainer = document.querySelector('.sunscool-container');
    window.scrollTo({ top: sunscoolContainer.offsetTop - 100, behavior: "smooth" });
  }

  selectBMBanner = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  showBanner = () => {
    if (window.innerWidth > 600) {
      return;
    }
    const bmBanner = document.querySelector('.bm-banner');
    const sunscoolBanner = document.querySelector('.sunscool-banner');
    const sunscoolContainer = document.querySelector('.sunscool-container');
    const cond = (window.scrollY + ((window.innerHeight / 13) * 2)) > sunscoolContainer.offsetTop;
    bmBanner.classList.toggle('active', cond);
    sunscoolBanner.classList.toggle('active', !cond);
    const element = sunscoolBanner.classList.contains('active') ? document.querySelector('.main > .col.bm-container') : document.querySelector('.main > .col.sunscool-container');
    element.classList.add('active');
    this.selectSection(element);
  }

  loadGame = () => {
    const script = document.createElement('script');
    const url = 'https://api.sunscool.org/xmas-star/';
    script.src = url + 'UnityLoader.js';
    script.onload = (e) => {
      window.UnityLoader.instantiate("gameContainer", url + "WebGL_4_rus_only.json");
    }
    document.body.appendChild(script);
  }

  openSunscoolVideo = () => {
    if (this.player) {
      const iframe = this.player.getIframe();
      iframe.parentNode.classList.add('active');
      document.body.classList.add('video-fullscreen');
      if (typeof this.player.playVideo === 'function') {
        this.player.playVideo();
      }
    }
  }

  closeSunscoolVideo = () => {
    if (this.player) {
      const iframe = this.player.getIframe();
      document.body.classList.remove('video-fullscreen');
      iframe.parentNode.classList.remove('active');
      if (typeof this.player.stopVideo === 'function') {
        this.player.stopVideo();
      }
    }
  }
}

export default App;
